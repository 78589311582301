import {Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-fee-validation-type-selection',
  templateUrl: './fee-validation-type-selection.component.html',
  styleUrls: ['./fee-validation-type-selection.component.scss']
})
export class FeeValidationTypeSelectionComponent {
  @Input() projectManagerValidationFc: FormControl<any>;
  @Input() label: string;
}
