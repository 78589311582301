<label for="feeValidationDirect" *ngIf="label">{{ label | translate }}</label>
<div class="form-group">
  <div class="custom-control custom-radio custom-control-inline">
    <input
      class="custom-control-input"
      type="radio"
      id="feeValidationDirect"
      [value]="false"
      name="feeValidation"
      [formControl]="projectManagerValidationFc">
    <label class="custom-control-label" for="feeValidationDirect">
      {{ 'company.update-company.fee-detail.validation-types.project-manager-validation' | translate }}
    </label>
  </div>
  <div class="custom-control custom-radio custom-control-inline">
    <input
      class="custom-control-input"
      type="radio"
      id="feeValidationDouble"
      [value]="true"
      name="feeValidation"
      [formControl]="projectManagerValidationFc">
    <label class="custom-control-label" for="feeValidationDouble">
      {{ 'company.update-company.fee-detail.validation-types.project-manager-adv-validation' | translate }}
    </label>
  </div>
</div>
