import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DeviceType, ScreenModel } from '@i-supplier/angular-shared-module';

@Component({
  selector: 'app-pagination-size',
  templateUrl: './pagination-size.component.html',
  styleUrls: ['./pagination-size.component.scss']
})
export class PaginationSizeComponent implements OnInit {
  @Input() size: number;
  @Input() allowedSizes: number[] = [8, 12, 20];
  @Input() currentScreenInfo: ScreenModel;
  @Output() sizeChange = new EventEmitter<number>();
  @Output() changeSize = new EventEmitter<void>();
  protected readonly DeviceType = DeviceType;

  ngOnInit(): void {
    if (!this.size) {
      this.size = this.allowedSizes[0];
      this.sizeChange.emit(this.size);
      this.changeSize.emit();
    }
  }
}
