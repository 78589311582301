import {HttpClient, HttpParams} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../assets/environments/environment';
import {commonProperties} from '../../../assets/environments/environment.common';
import {ListContentModel} from '../../shared/models/list-content.model';
import {CompanyTaxesPostModel, TaxNumber} from '../../tax-configuration-country/models/CompanyTaxesModel';
import {AdvancedSearchModel} from '../models/advanced-search.model';
import {AdministrativeManagerModel, CompanyCreationBodyModel} from '../models/company-creation-body.model';
import {CompanyItemModel} from '../models/company-item.model';
import {CompanyTypeEnum} from '../models/company-type.enum';
import {LegalInformationModel} from '../models/legal-information.model';
import {AuthorityModel} from '../../employee/models/employee-post-body.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private companyToUpdateSubject = new BehaviorSubject(null);
  public companyStatus = new EventEmitter<boolean>();

  constructor(
    private httpClient: HttpClient,
    private logger: NGXLogger
  ) {}

  mapFormValueToResource(value: any): CompanyCreationBodyModel {
    const result: CompanyCreationBodyModel = new CompanyCreationBodyModel();

    result.name = value.name;
    result.type = value.type;
    result.origin = value.origin.alpha2Code;
    result.incorporationNumber = value.incorporationNumber;

    if (value.administrativeManagerId) {
      result.administrativeManagerId = value.administrativeManagerId;
    } else {
      const administrativeManager: AdministrativeManagerModel = new AdministrativeManagerModel();
      administrativeManager.email = value.administrativeManager.email;
      administrativeManager.firstName = value.administrativeManager.firstName;
      administrativeManager.lastName = value.administrativeManager.lastName;
      administrativeManager.phoneNumber = value.administrativeManager.phoneNumber.internationalNumber.replace(/\s/g, '').replace('-', '');
      result.administrativeManager = administrativeManager;
    }
    result.hasDpo = value.hasDpo;
    if (value.hasDpo) {
      result.firstNameDpo = value.firstNameDpo;
      result.lastNameDpo = value.lastNameDpo;
      result.emailDpo = value.emailDpo;
    }
    if (value.parentCompany) {
      result.parentId = value.parentCompany.id;
    }
    return result;
  }

  createCompany(requestBody: CompanyCreationBodyModel) {
    return this.httpClient.post(environment.api_root + commonProperties.companyCreation, requestBody);
  }

  getCompaniesByPage(page: number, size: number, sort: string, search: string, advancedSearch: AdvancedSearchModel): Observable<ListContentModel> {
    let params = new HttpParams();
    if (search) {
      params = params.set('search', search);
    } else if (advancedSearch) {
      if (advancedSearch.name) {
        params = params.set('name', advancedSearch.name);
      }
      if (advancedSearch.level) {
        params = params.set('level', advancedSearch.level);
      }
      if (advancedSearch.types?.length > 0) {
        params = params.set('types', advancedSearch.types.join(','));
      }
      if (advancedSearch.incorporationNumber) {
        params = params.set('incorporationNumber', advancedSearch.incorporationNumber);
      }
      if (advancedSearch.status?.length > 0) {
        params = params.set('status', advancedSearch.status.join(','));
      }
      if (advancedSearch.parentName) {
        params = params.set('parentName', advancedSearch.parentName);
      }
    }

    params = params.set('page', page.toString()).set('size', size.toString());

    if (sort) {
      const sortPattern = /([^;,]+,[^;,]+);?/g;
      const sortMatches = sort.match(sortPattern);

      if (sortMatches) {
        const sortPairs = sortMatches.map(match => match.split(','));
        sortPairs.forEach(pair => {
          params = params.append('sort', pair[0] + ',' + pair[1]);
        });
      }
    }

    return this.httpClient.get<ListContentModel>(environment.api_root + commonProperties.companiesList, {params});
  }

  getSuppliersCompaniesByPage(search: string, page: number, size: number): Observable<ListContentModel> {
    let params = new HttpParams();
    params = params.set('name', search).set('page', page.toString()).set('size', size.toString()).set('types', 'SUPPLIER,FREELANCE');
    return this.httpClient.get<ListContentModel>(environment.api_root + commonProperties.suppliersList, {params});
  }

  mapAdvancedSearchForm(value: any): AdvancedSearchModel {
    const advancedSearch: AdvancedSearchModel = new AdvancedSearchModel();
    if (value.name) {
      advancedSearch.name = value.name;
    }
    if (value.status) {
      advancedSearch.status = [value.status];
    }
    if (value.incorporationNumber) {
      advancedSearch.incorporationNumber = value.incorporationNumber;
    }
    advancedSearch.types = [];
    if (value.buyer) {
      advancedSearch.types.push(CompanyTypeEnum.BUYER);
    }
    if (value.supplier) {
      advancedSearch.types.push(CompanyTypeEnum.SUPPLIER);
    }
    if (value.freelance) {
      advancedSearch.types.push(CompanyTypeEnum.FREELANCE);
    }
    if (value.parentName) {
      advancedSearch.parentName = value.parentName;
    }
    return advancedSearch;
  }

  getCompanyById(companyId: string): Observable<CompanyItemModel> {
    return this.httpClient.get<CompanyItemModel>(environment.api_root + commonProperties.companyById.replace(':companyId', companyId));
  }

  getLegalForms() {
    return this.httpClient.get(environment.api_root + commonProperties.legalForms);
  }

  mapLegalInformationFormToModel(value: any): LegalInformationModel {
    const result: LegalInformationModel = new LegalInformationModel();
    this.logger.info('value is:', value);
    result.id = value.id;
    result.name = value.name;
    result.managementMailbox = value.managementMailbox;
    result.origin = value.origin.alpha2Code;
    result.incorporationNumber = value.incorporationNumber;
    result.registrationCity = value.registrationCity;
    result.shareCapital = value.shareCapital;
    result.registrationNumber = value.registrationNumber;
    result.subjectToVat = value.TAX.subjectToVat;
    result.vatPercentage = value.vatPercentage;
    result.vatNumber = value.vatNumber;
    result.legalForm = value.legalForm;
    result.codeTiers = value.codeTiers;
    result.phoneNumber = value.phoneNumber.internationalNumber.replace(/\s/g, '').replace('-', '');
    if (value.legalForm === 'OTHER') {
      result.customLegalForm = value.customLegalForm;
    }
    if (result.bank && value.bank) {
      result.bank.bankName = value.bank.bankName;
      result.bank.iban = value.bank.iban;
      result.bank.bicOrSwift = value.bank.bicOrSwift;
      result.bank.ibanType = value.bank.ibanType;
    } else {
      result.bank = null;
    }
    result.hasDpo = value.hasDpo;
    if (result.hasDpo) {
      result.firstNameDpo = value.firstNameDpo;
      result.lastNameDpo = value.lastNameDpo;
      result.emailDpo = value.emailDpo;
    }
    return result;
  }

  mapLegalInformationToCompanyTaxesPostModel(tvaFormValue: { taxGroupId: string; numbers: TaxNumber[]; }) {
    const companyTaxesPostModel: CompanyTaxesPostModel = new CompanyTaxesPostModel();
    companyTaxesPostModel.numbers = [];
    companyTaxesPostModel.taxGroupId = tvaFormValue.taxGroupId;
    tvaFormValue.numbers.map(taxType => companyTaxesPostModel.numbers.push({number: taxType.number, taxTypeId: taxType.taxTypeId}));
    return companyTaxesPostModel;
  }

  updateCompanyById(legalInformationModel: LegalInformationModel) {
    return this.httpClient.put(environment.api_root + commonProperties.companyById.replace(':companyId', legalInformationModel.id), legalInformationModel);
  }


  getCompanyToUpdate(): Observable<CompanyItemModel> {
    return this.companyToUpdateSubject.asObservable();
  }

  setCompanyToUpdate(company) {
    this.companyToUpdateSubject.next(company);
  }

  getCompanyRoles(types: string): Observable<AuthorityModel[]> {
    let params = new HttpParams();
    params = params.set('types', types);
    return this.httpClient.get<AuthorityModel[]>(environment.api_root + commonProperties.authoritiesByCompanyType, {params});
  }

  getCountries() {
    return this.httpClient.get(environment.api_root + commonProperties.countries);
  }

  getCompanyInvoiceConfigByCompanyId(companyId: string): Observable<any> {
    return this.httpClient.get(`${environment.api_root}invoicingMgtApi/invoice-configs/${companyId}`);
  }

  getBillableCompaniesByPage(page: number, size: number, sort: string, search: string): Observable<ListContentModel> {
    let params = new HttpParams();
    if (search) {
      params = params.set('search', search);
    }
    params = params.set('page', page.toString()).set('size', size.toString()).set('sort', sort);
    return this.httpClient.get<ListContentModel>(`${environment.api_root}invoicingMgtApi/invoice-configs/billable-companies`, {params});
  }

  updateInvoiceConfig(value: any, companyId: string): Observable<any> {
    return this.httpClient.put(`${environment.api_root}invoicingMgtApi/invoice-configs/${companyId}`, value);
  }

  getRelatedEntities(companyId: string): Observable<CompanyItemModel[]> {
    const params = new HttpParams().set('companyId', companyId);
    return this.httpClient.get<CompanyItemModel[]>(environment.api_root + commonProperties.relatedEntities, {params});
  }

  patchCompany(companyId: string, companyPatchBodyResource: any): Observable<CompanyItemModel> {
    return this.httpClient.patch<CompanyItemModel>(`${environment.api_root}enterpriseMgtApi/companies/${companyId}`, companyPatchBodyResource);
  }
}
